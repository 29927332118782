import React, { useEffect, useState } from "react";
import { Fade, Zoom } from "react-reveal";
import Layout from "../../components/layout";
import { leaders } from "../aboutus";

const Leader = () => {
  const [leader, setLeader] = useState("");
  const [leaderInfo, setLeaderInfo] = useState({});
  useEffect(() => {
    //  search leader and set info
    if (leader.length > 0) {
      let leaderName = leader.split("-").join(" ");

      console.log(leaderName);
      let searchResult = leaders.filter((leader) => leader.name === leaderName);
      console.log(searchResult);
      setLeaderInfo(searchResult[0] ? searchResult[0] : "");
      if (searchResult.length === 0) {
        window.location = "/404";
      }
    }
  }, [leader]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.leader) {
      setLeader(params.leader);
    }
  }, []);

  return (
    <Layout isNavWhite={true} hideMap hideFAQ>
      <div className="leader-layout-wrapper">
        <div className="person-designation-wrapper">
          <Fade duration={1000}>
            <div className="left-image-wrapper">
              <img
                src={leaderInfo.profillePhoto ? leaderInfo.profillePhoto : ""}
                alt="leader"
              />
              <div className="right-border"></div>
            </div>
          </Fade>

          <div className="right-content-wrapper">
            <div className="name-wrapper">
              <Fade top duration={1000}>
                <h1 className="page-heading bold">{leaderInfo.name}</h1>
              </Fade>
              <Fade bottom duration={1000}>
                <p className="title bold">{leaderInfo.designation}</p>
              </Fade>
            </div>
          </div>
        </div>
        <div className="person-information-wrapper">
          <Fade duration={1000}>
            <p className="title regular">{leaderInfo.info}</p>
          </Fade>
        </div>
      </div>
    </Layout>
  );
};

export default Leader;
